import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../service/axios';
import { toast } from 'react-toastify';

export const getDepartment = createAsyncThunk('departmentSlice/getDepartment', async (data) => {
    try {
        let response = await axios.get('/department', {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: data?.loader
        });

        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);

        return Promise.reject(err);
    }
});

export const createDepartment = createAsyncThunk(
    'departmentSlice/createDepartment',
    async (data, { dispatch, rejectWithValue }) => {
        try {
            let response = await axios.post('/department', data, {
                headers: {
                    'Content-Type': 'application/json'
                },
                loader: true
            });
            toast.success(response?.data?.message || 'Department added successfully.');
            await dispatch(getDepartment());
            return Promise.resolve(response.data);
        } catch (err) {
            if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
                toast.error(err?.response?.data?.message || 'Something went wrong!');
            }
            console.log('err', err);

            return rejectWithValue(err);
        }
    }
);

export const updateDepartment = createAsyncThunk(
    'departmentSlice/updateDepartment',
    async (data, { dispatch, rejectWithValue }) => {
        try {
            let response = await axios.put('/department/' + data.id, data?.data, {
                headers: {
                    'Content-Type': 'application/json'
                },
                loader: true
            });
            toast.success(response?.data?.message || 'Department updated successfully.');
            dispatch(getDepartment());
            return Promise.resolve(response.data);
        } catch (err) {
            if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
                toast.error(err?.response?.data?.message || 'Something went wrong!');
            }
            console.log('err', err);

            return rejectWithValue(err);
        }
    }
);
export const deleteDepartment = createAsyncThunk('departmentSlice/deleteDepartment', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/department/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Department deleted successfully.');
        await dispatch(getDepartment());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateDepartmentStatus = createAsyncThunk('departmentSlice/updateDepartmentStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put(
            '/department/status/' + id,
            {},
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        );
        toast.success(response?.data?.message || 'Status updated successfully !');
        dispatch(getDepartment());
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getDepartmentOptions = createAsyncThunk('departmentSlice/getDepartmentOptions', async () => {
    try {
        let response = await axios.get('/department/options', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);

        return Promise.reject(err);
    }
});

export const departmentSlice = createSlice({
    name: 'departmentSlice',
    options: [],
    initialState: {
        data: [],
        options: []
    },

    extraReducers: (builder) => {
        builder.addCase(getDepartment.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getDepartmentOptions.fulfilled, (state, action) => {
            state.options = action?.payload;
            return;
        });
    }
});

export default departmentSlice.reducer;

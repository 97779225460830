import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

export const getAuditLog = createAsyncThunk('auditLogSlice/getAuditLog', async () => {
    try {
        let response = await axios.get('/audit-logs', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getAllAuditLogWithFilters = createAsyncThunk('auditLogSlice/getAllAuditLogWithFilters', async (data) => {
    try {
        let response = await axios.post('/audit-logs/with-filters', data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getAuditLogDetails = createAsyncThunk('auditLogSlice/getAuditLogDetails', async (id) => {
    try {
        let response = await axios.get('/audit-logs/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const auditLogSlice = createSlice({
    name: 'auditLogSlice',
    initialState: {
        selectedData: {}
    },
    extraReducers: (builder) => {
        builder.addCase(getAuditLogDetails.fulfilled, (state, action) => {
            state.selectedData = action?.payload;
            return;
        });
    }
});

export default auditLogSlice.reducer;

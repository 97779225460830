import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../service/axios';
import { toast } from 'react-toastify';

export const getAgency = createAsyncThunk('agencySlice/getAgency', async (_, { dispatch }) => {
    try {
        let response = await axios.get('/agency', {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: false
        });
        await dispatch(removeSelectedAgency());
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log(err, 'err');
        return Promise.reject(err);
    }
});

export const createAgency = createAsyncThunk('agencySlice/createAgency', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.post('/agency', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Agency added successfully.');
        dispatch(getAgency());

        return Promise.resolve(response.data);
    } catch (err) {
        if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
        }
        console.log(err, 'err');
        return rejectWithValue(err);
    }
});

export const updateAgency = createAsyncThunk('agencySlice/updateAgency', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.put('/agency/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Agency updated successfully.');
        dispatch(getAgency());
        return Promise.resolve(response.data);
    } catch (err) {
        if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
        }
        console.log(err, 'err');
        return rejectWithValue(err);
    }
});

export const deleteAgency = createAsyncThunk('agencySlice/deleteAgency', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/agency/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Agency deleted successfully.');
        await dispatch(getAgency());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateStatus = createAsyncThunk('agencySlice/updateStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put(
            '/agency/status/' + id,
            {},
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                loader: false
            }
        );
        await dispatch(getAgency());
        toast.success(response?.data?.message || 'status updated successfully !');
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getAgencyById = createAsyncThunk('agencySlice/getAgencyById', async (id) => {
    try {
        let response = await axios.get('/agency/' + id, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getAgencyOptions = createAsyncThunk('agencySlice/getAgencyOptions', async () => {
    try {
        let response = await axios.get('/agency/options', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);

        return Promise.reject(err);
    }
});

export const agencySlice = createSlice({
    name: 'agencySlice',
    initialState: {
        data: [],
        selectedAgency: {},
        options: []
    },
    reducers: {
        removeSelectedAgency: (state) => {
            state.selectedAgency = {};
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getAgency.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getAgencyById.fulfilled, (state, action) => {
            state.selectedAgency = action?.payload;
            return;
        });
        builder.addCase(getAgencyOptions.fulfilled, (state, action) => {
            state.options = action?.payload;
            return;
        });
    }
});
export const { removeSelectedAgency } = agencySlice.actions;
export default agencySlice.reducer;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../service/axios';
import { toast } from 'react-toastify';

export const getBrand = createAsyncThunk('brandSlice/getBrand', async (data) => {
    try {
        let response = await axios.get('/brand', {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: data?.loader
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const createBrand = createAsyncThunk('brandSlice/createBrand', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.post('/brand', data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Brand added successfully.');
        await dispatch(getBrand());

        return Promise.resolve(response.data);
    } catch (err) {
        if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
        }
        console.log('err', err);
        return rejectWithValue(err);
    }
});

export const updateBrand = createAsyncThunk('brandSlice/updateBrand', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.put('/brand/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Brand updated successfully.');
        dispatch(getBrand());

        return Promise.resolve(response.data);
    } catch (err) {
        if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
        }
        console.log('err', err);

        return rejectWithValue(err);
    }
});
export const deleteBrand = createAsyncThunk('brandSlice/deleteBrand', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/brand/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Brand deleted successfully.');
        await dispatch(getBrand());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateStatus = createAsyncThunk('subCategorySlice/updateStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put(
            '/brand/status/' + id,
            {},
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                loader: false
            }
        );
        toast.success(response?.data?.message || 'Status updated successfully !');
        dispatch(getBrand());
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const brandSlice = createSlice({
    name: 'brandSlice',
    initialState: {
        data: []
    },

    extraReducers: (builder) => {
        builder.addCase(getBrand.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
    }
});

export default brandSlice.reducer;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../service/axios';
import { toast } from 'react-toastify';
import showLoader from '@components/loader';
import queryString from 'query-string';

export const getMaterialSubCategory = createAsyncThunk('materialSubCategorySlice/getMaterialSubCategory', async (data) => {
    try {
        let response = await axios.get('/material-sub-category', {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: data?.loader
        });

        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);

        return Promise.reject(err);
    }
});

export const createMaterialSubCategory = createAsyncThunk(
    'materialSubCategorySlice/createMaterialSubCategory',
    async (data, { dispatch, rejectWithValue }) => {
        try {
            showLoader(true);
            let response = await axios.post('/material-sub-category', data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            toast.success(response?.data?.message || 'Material Sub Category added successfully.');
            await dispatch(getMaterialSubCategory());
            showLoader(false);
            return Promise.resolve(response.data);
        } catch (err) {
            if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
                toast.error(err?.response?.data?.message || 'Something went wrong!');
            }
            console.log('err', err);

            return rejectWithValue(err);
        }
    }
);

export const updateMaterialSubCategory = createAsyncThunk(
    'materialSubCategorySlice/updateMaterialSubCategory',
    async (data, { dispatch, rejectWithValue }) => {
        try {
            showLoader(true);
            let response = await axios.put('/material-sub-category/' + data.id, data?.data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            toast.success(response?.data?.message || 'Material Sub Category updated successfully.');
            dispatch(getMaterialSubCategory());
            showLoader(false);
            return Promise.resolve(response.data);
        } catch (err) {
            if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
                toast.error(err?.response?.data?.message || 'Something went wrong!');
            }
            console.log('err', err);
            return rejectWithValue(err);
        }
    }
);
export const deleteMaterialSubCategory = createAsyncThunk(
    'materialSubCategorySlice/deleteMaterialSubCategory',
    async (deleteId, { dispatch }) => {
        try {
            let response = await axios.delete('/material-sub-category/' + deleteId, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            toast.success(response?.data?.message || 'Material Sub Category deleted successfully.');
            await dispatch(getMaterialSubCategory());
            return Promise.resolve(response);
        } catch (err) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
            console.log('err', err);
            return Promise.reject(err);
        }
    }
);

export const updateCategoryStatus = createAsyncThunk('materialSubCategorySlice/updateCategoryStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put(
            '/material-sub-category/status/' + id,
            {},
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        );
        toast.success(response?.data?.message || 'Status updated successfully !');
        dispatch(getMaterialSubCategory());
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getMaterialSubCategoryOptions = createAsyncThunk('materialSubCategorySlice/getMaterialSubCategoryOptions', async (data) => {
    try {
        const query = queryString.stringify(data);
        let response = await axios.get('/material-sub-category/options?' + query, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const materialSubCategorySlice = createSlice({
    name: 'materialSubCategorySlice',
    options: [],
    initialState: {
        data: []
    },

    extraReducers: (builder) => {
        builder.addCase(getMaterialSubCategory.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
    }
});

export default materialSubCategorySlice.reducer;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../service/axios';
import { toast } from 'react-toastify';
import showLoader from '@components/loader';

export const getCategory = createAsyncThunk('categorySlice/getCategory', async (data) => {
    try {
        let response = await axios.get('/category', {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: data?.loader
        });

        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);

        return Promise.reject(err);
    }
});

export const createCategory = createAsyncThunk('categorySlice/createCategory', async (data, { dispatch, rejectWithValue }) => {
    try {
        showLoader(true);
        let response = await axios.post('/category', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Booth added successfully.');
        await dispatch(getCategory());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
        }
        console.log('err', err);

        return rejectWithValue(err);
    }
});

export const updateCategory = createAsyncThunk('categorySlice/updateCategory', async (data, { dispatch, rejectWithValue }) => {
    try {
        showLoader(true);
        let response = await axios.put('/category/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Nagar Parisad updated successfully.');
        dispatch(getCategory());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
        }
        console.log('err', err);

        return rejectWithValue(err);
    }
});
export const deleteCategory = createAsyncThunk('categorySlice/deleteCategory', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/category/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'category deleted successfully.');
        await dispatch(getCategory());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getCategoryOptions = createAsyncThunk('categorySlice/getCategoryOptions', async () => {
    try {
        let response = await axios.get('/category/options', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);

        return Promise.reject(err);
    }
});

export const updateCategoryStatus = createAsyncThunk('categorySlice/updateCategoryStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/category/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status updated successfully !');
        dispatch(getCategory());
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const categorySlice = createSlice({
    name: 'categorySlice',
    options: [],
    initialState: {
        data: [],
        options: []
    },

    extraReducers: (builder) => {
        builder.addCase(getCategory.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getCategoryOptions.fulfilled, (state, action) => {
            state.options = action?.payload;
            return;
        });
    }
});

export default categorySlice.reducer;

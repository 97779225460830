import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../service/axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import queryString from 'query-string';

export const getDistrict = createAsyncThunk('districtSlice/getDistrict', async () => {
    try {
        let response = await axios.get('/district', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getDistrictOptions = createAsyncThunk('districtSlice/getDistrictOptions', async (data) => {
    try {
        const query = queryString.stringify(data);
        let response = await axios.get('/district/options' + `?${query}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const createDistrict = createAsyncThunk('districtSlice/createDistrict', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.post('/district', data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'District added successfully.');
        dispatch(getDistrict());

        return Promise.resolve(response.data);
    } catch (err) {
        if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
        }
        console.log(err, 'err');
        return rejectWithValue(err);
    }
});

export const updateDistrict = createAsyncThunk('districtSlice/updateDistrict', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.put('/district/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'District updated successfully.');
        dispatch(getDistrict());

        return Promise.resolve(response.data);
    } catch (err) {
        if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
        }
        console.log('err', err);
        return rejectWithValue(err);
    }
});

export const deleteDistrict = createAsyncThunk('districtSlice/deleteDistrict', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/district/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'District deleted successfully.');
        await dispatch(getDistrict());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateDistrictStatus = createAsyncThunk('districtSlice/updateDistrictStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put(
            '/district/status/' + id,
            {},
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                loader: true
            }
        );
        await dispatch(getDistrict());
        toast.success(response?.data?.message || 'Status updated successfully !');

        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const districtSlice = createSlice({
    name: 'districtSlice',
    initialState: {
        data: [],
        options: []
    },

    extraReducers: (builder) => {
        builder.addCase(getDistrict.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getDistrictOptions.fulfilled, (state, action) => {
            state.options = action?.payload;
            return;
        });
    }
});

export default districtSlice.reducer;

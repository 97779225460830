import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

// Async thunks
export const getProfileData = createAsyncThunk('profileSlice/getProfileData', async () => {
    try {
        const response = await axios.get('/profile', {
            headers: { 'Content-Type': 'application/json' },
            loader: true
        });
        return response.data.data;
    } catch (err) {
        toast.error(err.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateProfile = createAsyncThunk('profileSlice/updateProfile', async (data, { dispatch }) => {
    try {
        const response = await axios.put('/profile', data, {
            headers: { 'Content-Type': 'multipart/form-data' }
        });
        toast.success(response?.data?.message || 'Profile updated successfully!');
        dispatch(getProfileData());
        return response.data;
    } catch (err) {
        toast.error(err.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const removeProfileImage = createAsyncThunk('profileSlice/removeProfileImage', async (data, { dispatch }) => {
    try {
        const response = await axios.put('/profile/remove-image', data, {
            headers: { 'Content-Type': 'application/json' },
            loader: true
        });
        toast.success(response?.data?.message || 'Profile image deleted successfully!');

        dispatch(getProfileData());
        return response.data;
    } catch (err) {
        toast.error(err.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const verifyEmail = createAsyncThunk('profileSlice/verifyEmail', async () => {
    try {
        const response = await axios.get('/verify', {
            headers: { 'Content-Type': 'application/json' },
            loader: true
        });
        toast.success(response?.data?.message || 'Mail sent successfully');

        return response.data;
    } catch (err) {
        toast.error(err.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

const profileSlice = createSlice({
    name: 'profileSlice',
    initialState: {
        profile: {}
    },
    extraReducers: (builder) => {
        builder.addCase(getProfileData.fulfilled, (state, action) => {
            state.profile = action.payload;
            return;
        });
    }
});

export default profileSlice.reducer;

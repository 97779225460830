import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../service/axios';
import { toast } from 'react-toastify';

export const getDesignation = createAsyncThunk('designationSlice/getDesignation', async (data) => {
    try {
        let response = await axios.get('/designation', {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: data?.loader
        });

        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);

        return Promise.reject(err);
    }
});

export const createDesignation = createAsyncThunk('designationSlice/createDesignation', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.post('/designation', data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Designation added successfully.');
        await dispatch(getDesignation());
        return Promise.resolve(response.data);
    } catch (err) {
        if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
        }
        console.log('err', err);

        return rejectWithValue(err);
    }
});

export const updateDesignation = createAsyncThunk('designationSlice/updateDesignation', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.put('/designation/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Designation updated successfully.');
        dispatch(getDesignation());
        return Promise.resolve(response.data);
    } catch (err) {
        if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
        }
        console.log('err', err);

        return rejectWithValue(err);
    }
});
export const deleteDesignation = createAsyncThunk('designationSlice/deleteDesignation', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/designation/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Designation deleted successfully.');
        await dispatch(getDesignation());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateDesignationStatus = createAsyncThunk('designationSlice/updateDesignationStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/designation/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status updated successfully !');
        dispatch(getDesignation());
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getDesignationOptions = createAsyncThunk('designationSlice/getDesignationOptions', async (id) => {
    try {
        let response = await axios.get('/designation/options', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);

        return Promise.reject(err);
    }
});

export const designationSlice = createSlice({
    name: 'designationSlice',
    initialState: {
        data: [],
        options: []
    },
    extraReducers: (builder) => {
        builder.addCase(getDesignation.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getDesignationOptions.fulfilled, (state, action) => {
            state.options = action?.payload;
            return;
        });
    }
});

export default designationSlice.reducer;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

export const getAppUser = createAsyncThunk('appUserSlice/getAppUser', async (_, { dispatch }) => {
    try {
        let response = await axios.get('/app-user', {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: false
        });
        dispatch(removeSelectedUser());
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createAppUser = createAsyncThunk('appUserSlice/createAppUser', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.post('/app-user', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'App User added successfully.');
        dispatch(getAppUser());

        return Promise.resolve(response.data);
    } catch (err) {
        if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
        }
        console.log(err, 'err');
        return rejectWithValue(err);
    }
});

export const updateAppUser = createAsyncThunk('appUserSlice/updateAppUser', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.put('/app-user/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'App User updated successfully.');
        dispatch(getAppUser());

        return Promise.resolve(response.data.data);
    } catch (err) {
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        // return Promise.reject(err);
        if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
        }
        console.log('err', err);
        return rejectWithValue(err);
    }
});

export const getAppUserById = createAsyncThunk('appUserSlice/getAppUserById', async (id) => {
    try {
        let response = await axios.get('/app-user/' + id, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });

        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteAppUser = createAsyncThunk('appUserSlice/deleteAppUser', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/app-user/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'App User deleted successfully');
        dispatch(getAppUser());

        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateStatus = createAsyncThunk('appUserSlice/updateStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put(
            '/app-user/status/' + id,
            {},
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                loader: false
            }
        );
        toast.success(response?.data?.message || 'Status updated successfully.');
        dispatch(getAppUser());
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.err(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getAppUserRoleOptions = createAsyncThunk('appUserSlice/getAppUserRoleOptions', async (id) => {
    try {
        let response = await axios.get('/app-user/role/options', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);

        return Promise.reject(err);
    }
});

export const getAppUserOptions = createAsyncThunk('projectSlice/getAppUserOptions', async (data) => {
    try {
        let response = await axios.post('/app-user/options/with-filter', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const appUserSlice = createSlice({
    name: 'appUserSlice',
    initialState: {
        data: [],
        appUserRollOptions: [],
        appUserOptions: [],
        selectedUser: {}
    },
    reducers: {
        handleSelectedUser: (state, action) => {
            state.selectedUser = action.payload;
        },
        removeSelectedUser: (state, action) => {
            state.selectedUser = {};
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getAppUser.fulfilled, (state, action) => {
            state.data = action.payload;
            return;
        });
        builder.addCase(getAppUserRoleOptions.fulfilled, (state, action) => {
            state.appUserRollOptions = action.payload;
            return;
        });
        builder.addCase(getAppUserById.fulfilled, (state, action) => {
            state.selectedUser = action.payload;
            return;
        });
        builder.addCase(getAppUserOptions.fulfilled, (state, action) => {
            state.appUserOptions = action?.payload;
            return;
        });
    }
});

export const { handleSelectedUser, removeSelectedUser } = appUserSlice.actions;

export default appUserSlice.reducer;

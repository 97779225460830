import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

export const getMixDesign = createAsyncThunk('mixDesignSlice/getMixDesign', async (_, { dispatch }) => {
    try {
        let response = await axios.get('/mix-design', {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: false
        });
        await dispatch(removeSelectedMixDesign());
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getMixDesignById = createAsyncThunk('mixDesignSlice/getMixDesignById', async (id) => {
    try {
        let response = await axios.get('/mix-design/' + id, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createMixDesign = createAsyncThunk('mixDesignSlice/createMixDesign', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.post('/mix-design', data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Mix Design added successfully.');
        await dispatch(getMixDesign());
        return Promise.resolve(response.data);
    } catch (err) {
        if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
        }
        console.log('err', err);

        return rejectWithValue(err);
    }
});

export const updateMixDesign = createAsyncThunk('mixDesignSlice/updateMixDesign', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.put('/mix-design/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Mix Design updated successfully.');
        await dispatch(getMixDesign());
        return Promise.resolve(response.data);
    } catch (err) {
        if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
        }
        console.log('err', err);

        return rejectWithValue(err);
    }
});

export const deleteMixDesign = createAsyncThunk('mixDesignSlice/deleteMixDesign', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/mix-design/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Mix Design deleted successfully.');
        await dispatch(getMixDesign());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);

        return rejectWithValue(err);
    }
});
export const updateStatus = createAsyncThunk('mixDesignSlice/updateStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put(
            '/mix-design/status/' + id,
            {},
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                loader: false
            }
        );
        toast.success(response?.data?.message || 'Status updated successfully !');
        await dispatch(getMixDesign());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);

        return Promise.reject(err);
    }
});

export const mixDesignSlice = createSlice({
    name: 'mixDesignSlice',
    initialState: {
        data: [],
        selectedMixDesign: {}
    },
    reducers: {
        removeSelectedMixDesign: (state, action) => {
            state.selectedMixDesign = {};
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getMixDesign.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getMixDesignById.fulfilled, (state, action) => {
            state.selectedMixDesign = action?.payload;
            return;
        });
    }
});

export const { removeSelectedMixDesign } = mixDesignSlice.actions;
export default mixDesignSlice.reducer;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../service/axios';
import { toast } from 'react-toastify';
import queryString from 'query-string';

export const getSubCategory = createAsyncThunk('subCategorySlice/getSubCategory', async (data) => {
    try {
        let response = await axios.get('/sub-category', {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: data?.loader
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);

        return Promise.reject(err);
    }
});

export const createSubCategory = createAsyncThunk('subCategorySlice/createSubCategory', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.post('/sub-category', data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'SubCategory added successfully.');
        await dispatch(getSubCategory());

        return Promise.resolve(response.data);
    } catch (err) {
        if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
        }
        console.log('err', err);

        return rejectWithValue(err);
    }
});

export const updateSubCategory = createAsyncThunk('subCategorySlice/updateSubCategory', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.put('/sub-category/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'SubCategory updated successfully.');
        dispatch(getSubCategory());

        return Promise.resolve(response.data);
    } catch (err) {
        if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
        }
        console.log('err', err);

        return rejectWithValue(err);
    }
});
export const deleteSubCategory = createAsyncThunk('subCategorySlice/deleteSubCategory', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/sub-category/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'SubCategory deleted successfully.');
        await dispatch(getSubCategory());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getSubCategoryOptions = createAsyncThunk('subCategorySlice/getSubCategoryOptions', async (data) => {
    try {
        const query = queryString.stringify(data);
        let response = await axios.get('/sub-category/options?' + query, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateStatus = createAsyncThunk('subCategorySlice/updateStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put(
            '/sub-category/status/' + id,
            {},
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                loader: false
            }
        );
        toast.success(response?.data?.message || 'Status updated successfully !');
        dispatch(getSubCategory());
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const subCategorySlice = createSlice({
    name: 'subCategorySlice',
    initialState: {
        data: [],
        options: []
    },

    extraReducers: (builder) => {
        builder.addCase(getSubCategory.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
    }
});

export default subCategorySlice.reducer;

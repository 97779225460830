import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

export const getStockIn = createAsyncThunk('stockInSlice/getStockIn', async (status, { dispatch }) => {
    try {
        let response = await axios.get('/stock-in', {
            params: { status },
            headers: {
                'Content-Type': 'application/json'
            }
        });
        await dispatch(removeSelectedStockIn());
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getStockInById = createAsyncThunk('stockInSlice/getStockInById', async (id) => {
    try {
        let response = await axios.get('/stock-in/' + id, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createStockIn = createAsyncThunk('stockInSlice/createStockIn', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.post('/stock-in', data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Stock In added successfully.');
        await dispatch(getStockIn());
        return Promise.resolve(response.data);
    } catch (err) {
        if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
        }
        console.log('err', err);

        return rejectWithValue(err);
    }
});

export const updateStockIn = createAsyncThunk('stockInSlice/updateStockIn', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.put('/stock-in/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Stock In updated successfully.');
        await dispatch(getStockIn());
        return Promise.resolve(response.data);
    } catch (err) {
        if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
        }
        console.log('err', err);

        return rejectWithValue(err);
    }
});

export const deleteStockIn = createAsyncThunk('stockInSlice/deleteStockIn', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/stock-in/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Stock In deleted successfully.');
        await dispatch(getStockIn());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);

        return rejectWithValue(err);
    }
});

export const approveStockIn = createAsyncThunk('stockInSlice/approveStockIn', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/stock-transaction/' + id, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Stock In Status updated successfully !');
        await dispatch(getStockIn('1'));
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');

        return Promise.reject(err);
    }
});

export const getStockInNo = createAsyncThunk('stockInSlice/getStockInNo', async (id) => {
    try {
        let response = await axios.get('/sequence', {
            params: { code: 1 },
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.data.code;
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getUnitAndTaxFromMaterial = createAsyncThunk('stockInSlice/getUnitAndTaxFromMaterial', async (id) => {
    try {
        let response = await axios.get('/material/unit/' + `${id}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const stockInSlice = createSlice({
    name: 'stockInSlice',
    initialState: {
        data: [],
        selectedStockIn: {}
    },
    reducers: {
        removeSelectedStockIn: (state, action) => {
            state.selectedStockIn = {};
        },
        resetDataSource: (state, action) => {
            state.data = [];
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getStockIn.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getStockInById.fulfilled, (state, action) => {
            state.selectedStockIn = action?.payload;
            return;
        });
    }
});

export const { removeSelectedStockIn, resetDataSource } = stockInSlice.actions;
export default stockInSlice.reducer;

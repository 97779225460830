import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../service/axios';
import { toast } from 'react-toastify';

export const getState = createAsyncThunk('stateSlice/getState', async () => {
    try {
        let response = await axios.get('/state', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getStateOptions = createAsyncThunk('stateSlice/getStateOptions', async () => {
    try {
        let response = await axios.get('/state/options', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);

        return Promise.reject(err);
    }
});

export const createState = createAsyncThunk('stateSlice/createState', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.post('/state', data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'State added successfully.');
        dispatch(getState());

        return Promise.resolve(response.data);
    } catch (err) {
        if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
        }
        console.log('err', err);
        return rejectWithValue(err);
    }
});

export const updateState = createAsyncThunk('stateSlice/updateState', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/state/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'State updated successfully.');
        dispatch(getState());

        return Promise.resolve(response.data);
    } catch (err) {
        if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
        }
        console.log('err', err);

        return rejectWithValue(err);
    }
});

export const deleteState = createAsyncThunk('stateSlice/deleteBuyingRole', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/state/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'State deleted successfully.');
        await dispatch(getState());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const updateStatus = createAsyncThunk('stateSlice/updateStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put(
            '/state/status/' + id,
            {},
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                loader: false
            }
        );
        await dispatch(getState());
        toast.success(response?.data?.message || 'status updated successfully !');

        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);

        return Promise.reject(err);
    }
});
export const stateSlice = createSlice({
    name: 'stateSlice',
    initialState: {
        data: [],
        options: []
    },

    extraReducers: (builder) => {
        builder.addCase(getState.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getStateOptions.fulfilled, (state, action) => {
            state.options = action?.payload;
            return;
        });
    }
});

export default stateSlice.reducer;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../service/axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const getCity = createAsyncThunk('citySlice/getCity', async () => {
    try {
        let response = await axios.get('/city', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log(err, 'err');
        return Promise.reject(err);
    }
});

export const createCity = createAsyncThunk('citySlice/createCity', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.post('/city', data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'City added successfully.');
        dispatch(getCity());

        return Promise.resolve(response.data);
    } catch (err) {
        if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
        }
        console.log(err, 'err');
        return rejectWithValue(err);
    }
});

export const updateCity = createAsyncThunk('citySlice/updateCity', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.put('/city/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'City updated successfully.');
        dispatch(getCity());

        return Promise.resolve(response.data);
    } catch (err) {
        if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
        }
        console.log(err, 'err');
        return rejectWithValue(err);
    }
});

export const deleteCity = createAsyncThunk('citySlice/deleteCity', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/city/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'City deleted successfully.');
        await dispatch(getCity());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateStatus = createAsyncThunk('citySlice/updateStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put(
            '/city/status/' + id,
            {},
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                loader: false
            }
        );
        await dispatch(getCity());

        toast.success(response?.data?.message || 'status updated successfully !');

        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const citySlice = createSlice({
    name: 'citySlice',
    initialState: {
        data: []
        // options:[],
    },

    extraReducers: (builder) => {
        builder.addCase(getCity.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
    }
});

export default citySlice.reducer;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

// auth config import
import authConfig from '@src/auth/config.js';

// ** Service Constant Import
import service from '@src/service/constant';
import themeConfig from '../../../../configs/themeConfig';

const initialProjectSetting = () => {
    const data = JSON.parse(localStorage.getItem(authConfig.storageProjectSettingKeyName));
    if (data) {
        if (data.logo) {
            themeConfig.app.appLogo = data.logo;
        }
        if (data.favicon) {
            themeConfig.app.appFavIcon = data.favicon;
        }
        if (data.icon) {
            themeConfig.app.appIcon = data.icon;
        }
        if (data.loader) {
            themeConfig.app.appLoader = data.loader;
        }
        return data;
    }
    return [];
};

export const getProjectSetting = createAsyncThunk('projectSettingSlice/getProjectSetting', async () => {
    try {
        let response = await axios.get('/project-setting', {
            headers: {
                'Content-Type': 'application/json',
                isLoggedIn:
                    localStorage.getItem(authConfig.storageUserDataKeyName) && localStorage.getItem(authConfig.storageTokenKeyName)
                        ? true
                        : false
            },
            loader: true
        });
        // Destructure the response data
        let projectSetting = response.data.data;
        if (projectSetting.loader) {
            projectSetting.loader = `${service.API_URL}/${projectSetting.loader}`;
        }
        if (projectSetting.logo) {
            projectSetting.logo = `${service.API_URL}/${projectSetting.logo}`;
        }
        if (projectSetting.icon) {
            projectSetting.icon = `${service.API_URL}/${projectSetting.icon}`;
        }
        if (projectSetting.favicon) {
            projectSetting.favicon = `${service.API_URL}/${projectSetting.favicon}`;
        }
        localStorage.setItem(authConfig.storageProjectSettingKeyName, JSON.stringify(projectSetting));
        // return projectSetting;
        return Promise.resolve(projectSetting);
    } catch (err) {
        console.log('err', err);
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const removeProjectSetting = createAsyncThunk('projectSettingSlice/removeProjectSetting', async (imageType, { dispatch }) => {
    const urlParams = {
        imageType
    };
    try {
        const response = await axios.delete('/project-setting/remove-images', {
            params: urlParams,
            headers: { 'Content-Type': 'application/json' }
        });
        toast.success(response?.data?.message || 'Profile image deleted successfully!');
        dispatch(getProjectSetting());
        return response.data;
    } catch (err) {
        toast.error(err.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createProjectPortal = createAsyncThunk('projectSettingSlice/createProjectPortal', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/project-setting', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Settings added successfully.');
        dispatch(getProjectSetting());
        return Promise.resolve(response.data.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const projectSettingSlice = createSlice({
    name: 'projectSettingSlice',
    initialState: {
        projectSetting: initialProjectSetting()
    },
    reducers: {
        handleProjectSettingChange: (state, action) => {
            state.projectSetting = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getProjectSetting.fulfilled, (state, action) => {
            projectSettingSlice.caseReducers.handleProjectSettingChange(state, action);
        });
    }
});

export const { handleProjectSettingChange } = projectSettingSlice.actions;
export default projectSettingSlice.reducer;
